/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchAddons } from '../../../api';
import { useTranslation } from "react-i18next"
import '../HomePage.css'
import '../../common/styles/main.css'
import NO_IMG from '../../../assets/image/MOWS_no_image.svg';

const MajorAddon = () => {
    const { t } = useTranslation();
    const [addons, setAddons] = useState(null);
    const [filteredAddons, setFilteredAddons] = useState([]);

    useEffect(() => {
        fetchAddons().then(data => {
            if (data !== -1) {
                setAddons(data);
                setFilteredAddons(data);
            }
        });
    }, []);

    useEffect(() => {
        if (addons) {
            let sortedAddons = [...addons];
            sortedAddons.sort((a, b) => new Date(b.update_date) - new Date(a.update_date));
            setFilteredAddons(sortedAddons);
        }
    }, [addons])

    return (
        <section className="sections" id='addons'>
            <h2>{t('HomePage_MainContent_majoraddons')}</h2>
            <div>
                <Link to='addons' className="addons_button">
                    <div className="addon-icon">
                        <span translate="no" className="material-symbols-outlined">
                            explore
                        </span>
                    </div>
                    <h3>{t('HomePage_MainContent_majoraddons-more')}</h3>
                </Link>
                {filteredAddons.map(i => (
                    <Link to={`addons/${i.code_name}`} className="addons_button">
                        <img className='addon-icon' src={i.addon_icon ? i.addon_icon : NO_IMG} />
                        <h3>{i.code_name}</h3>
                        <div class='addons_info'>
                            <h4><span translate='no' className="material-symbols-outlined">account_circle</span>{i.developer}</h4>
                            <h4><span translate='no' className="material-symbols-outlined">download</span>{i.download_count}</h4>
                            <h4><span translate='no' className="material-symbols-outlined">update</span>{new Date(i.latest_version_date).toLocaleDateString()}</h4>
                        </div>
                    </Link>
                ))}
            </div>
        </section>
    )
};

export default MajorAddon;