import React from 'react';
import { Link } from 'react-router-dom';
import './error.css';

const NotFoundPage = ({ reason = 'wrong path' }) => {
    return (
        <div className="not-found-page">
            <h1> &#62; _ &#60; Oh no!&#160;&#160;Page Not Found!</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
            <Link to="/">Go to Home</Link>
        </div>
    );
};

export default NotFoundPage;