import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import NoServerResponsePage from '../error/no server response'
import NotFoundPage from '../error/404 not found';
import PageTitle from './components/PageTitle';
import '../common/styles/main.css'
import './AddonDetailPage.css';
import { fetchAddons, isExisting } from '../../api';
import { useTranslation } from 'react-i18next';
import AddonImages from './components/AddonImages';
import AddonInfo from './components/AddonInfo';
import DownloadButton from './components/DownloadButton';
import Loading from '../common/components/Loading';


const AddonDetailPage = () => {
    const { t } = useTranslation()
    const { code_name } = useParams();
    const [addon, setAddon] = useState(null);
    const [download_count, setDc] = useState('');
    const [external_addon_status, setEAS] = useState('');

    useEffect(() => {
        const checkExistenceAndFetch = async () => {
            const exist = await isExisting(code_name); // await 사용
            console.log('애드온 존재함 = ' + exist);
            if (exist === 1) {
                const res = await fetchAddons(code_name);
                let formatted;
                let downloadCount = res.download_count;
                if (downloadCount < 10) formatted = 'AddonDetailPage-curt_info-download-less_than_10'
                else if (downloadCount < 50) formatted = '10+';
                else if (downloadCount < 100) formatted = '50+';
                else if (downloadCount < 500) formatted = '100+';
                else if (downloadCount < 1000) formatted = '500+';
                else formatted = (downloadCount / 1000).toFixed(1) + 'k';

                if (res.instances.at(-1).need_external_addon === 0) {
                    setEAS('X')
                }
                else if (res.instances.at(-1).need_external_addon === 1) {
                    setEAS('▲')
                }
                else {
                    setEAS('O')
                }
                setDc(formatted);
                setAddon(res);
            }
            else if (exist === 0) {
                setAddon('No-Such-Addon')
            }
            else {
                setAddon('No-Server-Response')
            }
        };
        checkExistenceAndFetch();
    }, [code_name]);

    const getSeason = () => {
        const month = new Date().getMonth();
        if (month >= 2 && month <= 4) {
            return "spring"; // 3월 ~ 5월
        } else if (month >= 5 && month <= 7) {
            return "summer"; // 6월 ~ 8월
        } else if (month >= 8 && month <= 10) {
            return "autumn"; // 9월 ~ 11월
        } else {
            return "winter"; // 12월 ~ 2월
        }
    };

    if (!addon) {
        return (
            <Loading />
        )
    }
    else if (addon === 'No-Such-Addon') {
        return (
            <NotFoundPage reason={'No Such Addon'} />
        )
    }
    else if (addon === 'No-Server-Response') {
        return (
            <NoServerResponsePage />
        )
    }
    else {
        const selectedLanguage = t.language;  // i18n에서 선택된 언어
        const supportedLanguages = addon.supported_languages;
        const isLanguageSupported = supportedLanguages.includes(selectedLanguage);

        // 표시할 언어 및 나머지 개수를 결정
        const primaryLanguage = isLanguageSupported
            ? selectedLanguage.toUpperCase()
            : supportedLanguages[0].toUpperCase(); // 선택된 언어가 지원되지 않으면 첫 번째 언어 표시

        const additionalLanguageCount = supportedLanguages.length - 1; // +X에 표시할 추가 언어 개수
        return (
            <main className="addon-detail-page" id={getSeason()}>
                <div id='head'>
                    <img id='preview_image' src={addon.preview_image} alt='' />
                    <section>
                        <div id='go_to_back'>
                            <Link to='/addons' title='Addons'>
                                <span translate='no' className="material-symbols-outlined"> arrow_back_ios </span>{t('AddonDetailPage-return_to_back')}
                            </Link>
                        </div>
                        <PageTitle addon_name={t(addon.code_name)} addon_img={null} />
                        <table>
                            <tr>
                                <td title={t('AddonDetailPage-curt_info-download')}>{t('AddonDetailPage-curt_info-download')}</td>
                                <td title={t('AddonDetailPage-curt_info-third_necessity')}>{t('AddonDetailPage-curt_info-third_necessity')}</td>
                                <td title={t('AddonDetailPage-curt_info-exp_necessity')}>{t('AddonDetailPage-curt_info-exp_necessity')}</td>
                                <td title={t('AddonDetailPage-curt_info-developer')}>{t('AddonDetailPage-curt_info-developer')}</td>
                                <td title={t('AddonDetailPage-curt_info-lang_support')}>{t('AddonDetailPage-curt_info-lang_support')}</td>
                            </tr>
                            <tr>
                                <td>{t(download_count)}</td>
                                <td>{external_addon_status}</td>
                                <td>{addon.need_experiment ? 'O' : 'X'}</td>
                                <td>{addon.developer}</td>
                                <td><a id='lang_info' href='#addon-infos'>{primaryLanguage}{additionalLanguageCount > 0 && ` +${additionalLanguageCount}`}</a></td>
                            </tr>
                        </table>
                    </section>
                </div>
                <div id='body'>
                    <section>
                        <h2>{t('AddonDetailPage-AddonInfo-addon_screenshots')}</h2>
                        <AddonImages images={addon.screenshots} />

                        {/* 애드온 설명 */}
                        <h2>{t('AddonDetailPage-AddonInfo-addon_desc')}</h2>
                        <p id='description'>{t(`addon_description-${code_name}`) === `addon_description-${code_name}` ? 'N/A' : t(`addon_description-${code_name}`)}</p>

                        {/* 애드온 정보 */}
                        <h2>{t('AddonDetailPage-AddonInfo-addon_infos')}</h2>
                        <AddonInfo addon={addon} t={t} />

                        {/* 약관 */}
                        <h2 id='term_of_use'>{t('AddonDetailPage-AddonInfo-term')}</h2>
                        <ol>
                            <li>
                                본 애드온은 개인적 용도로만 사용할 수 있으며, 상업적 목적으로의 사용을 엄격히 금지합니다.<br />
                                This addon is for personal use only and is strictly prohibited for commercial purposes.
                            </li>
                            <li>
                                애드온의 모든 지적 재산권은 제작자에게 있으며, 사용자는 권리를 양도받지 않습니다.<br />
                                All intellectual property rights of the addon are retained by the creator, and no rights are transferred to the user.
                            </li>
                            <li>
                                애드온을 수정, 복제, 재배포하거나 파일 내의 내용을 추출할 수 없습니다.<br />
                                Users may not modify, reproduce, redistribute, or extract any contents from the addon files.
                            </li>
                            <li>
                                애드온과 관련된 이미지의 캡처 및 녹화 후 게시는 제작자와 MOWS 다운로드 사이트의 링크 표기 후에만 가능합니다.<br />
                                Capturing and sharing images related to the addon is allowed only after linking to the creator and the MOWS download site.
                            </li>
                            <li>
                                사용자는 애드온을 합법적이고 적절한 방식으로 사용해야 하며, 다른 사용자의 권리를 침해해서는 안 됩니다.<br />
                                Users must use the addon legally and appropriately, and must not infringe upon the rights of other users.
                            </li>
                            <li>
                                애드온 사용으로 인한 모든 문제에 대한 책임은 사용자 본인에게 있으며, 제작자는 이에 대한 법적 책임을 지지 않습니다.<br />
                                Users are solely responsible for any issues arising from the use of the addon, and the creator assumes no legal liability.
                            </li>
                            <li>
                                추가적인 개인정보는 수집되지 않습니다.<br />
                                No additional personal information is collected.
                            </li>
                            <li>
                                제작자는 사전 통지 없이 애드온의 배포를 중단하거나 변경할 수 있는 권리를 보유합니다.<br />
                                The creator reserves the right to discontinue or change the distribution of the addon without prior notice.
                            </li>
                            <li>
                                제작자는 언제든지 사전 통보 없이 본 약관을 수정할 수 있습니다.<br />
                                The creator may modify these terms at any time without prior notification.
                            </li>
                        </ol>
                        <DownloadButton addon={addon} code_name={code_name} t={t} />
                    </section>
                </div>
            </main>
        );
    }
};

export default AddonDetailPage;