import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import HomePage from './pages/home/HomePage';
import AddonsPage from './pages/addons/AddonsPage';
import AddonDetailPage from './pages/addonDetail/AddonDetailPage';
import NotFoundPage from './pages/error/404 not found';
import './index.css'
import './pages/common/styles/main.css'
import Layout from './pages/common/components/Layout';
import Loading from './pages/common/components/Loading';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 정리
  }, []);

  if (loading)
    return (<Loading />)
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='addons/:code_name' element={<AddonDetailPage />} />
          <Route path='addons' element={<AddonsPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;