import React from 'react';
import { Link } from 'react-router-dom';
import '../common/styles/main.css'
// import './NotFoundPage.css';

const NoServerResponsePage = () => {
    return (
        <div className="no-server-response-page">
            <h1><span translate='no' className="material-symbols-outlined"> database_off </span>서버 응답 없음. No Server Response.</h1>
            <h2>나중에 다시 시도하세요. Try again later.</h2>
            <p>다음을 확인하고 다시 시도하세요. Check the following and try again.</p>
            <ol>
                <li>네트워크 연결 없음. No network connection.</li>
                <li>서버 점검 및 오작동. Server maintanance or malfunction.</li>
                <li>접근 거부. Approach denied</li>
            </ol>
            <p>* 00:00(UTC+9) ~ 00:05(UTC+9) 는 서버 점검 시간입니다.
                * 00:00(UTC+9) ~ 00:05(UTC+9) is server maintenance time.</p>
            <Link to="/">Go to Home</Link>
        </div>
    );
};

export default NoServerResponsePage;