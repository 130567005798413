import React from "react";
import { useTranslation } from "react-i18next"
import '../HomePage.css'
import '../../common/styles/main.css'

const SocialLink = () => {
    const { t } = useTranslation();
    return (<section className="sections" id='social'>
        <h2>{t('HomePage_MainContent_sociallink')}</h2>
        <div>
            <a href="https://www.youtube.com/@minecart1019"><img src='https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_light_icon_%282017%29.svg' alt="no" />Youtube</a>
        </div>
    </section>)
}

export default SocialLink;