import React from 'react';
import '../AddonDetailPage.css';

const AddonInfo = ({ addon, t }) => {
    const updateDate = new Date(addon.latest_version_date);
    const uploadDate = new Date(addon.oldest_version_date);
    console.log(addon.update_date)
    return (
        <div id="addon-infos">
            <p>{t('AddonDetailPage-AddonInfo-addon_infos-developer')}:<b> {addon.developer}</b></p>
            <p>{t('AddonDetailPage-AddonInfo-addon_infos-download_count')}:<b> <span id='download_count'>{addon.download_count}</span></b></p>
            <p>{t('AddonDetailPage-AddonInfo-addon_infos-latest_version')}:<b> {addon.instances.at(-1).version}</b></p>
            <p>{t('AddonDetailPage-AddonInfo-addon_infos-available_version')}:<b> {addon.instances.filter(instance => instance.download_available).length}</b></p>
            <p>{t('AddonDetailPage-AddonInfo-addon_infos-last_updates')}:<b> {updateDate.toLocaleDateString()}</b></p>
            <p>{t('AddonDetailPage-AddonInfo-addon_infos-first_upload')}:<b> {uploadDate.toLocaleDateString()}</b></p>
            <p>{t('AddonDetailPage-AddonInfo-addon_infos-support_lang')}:<b> {addon.supported_languages.join(', ').toUpperCase()}</b></p>
        </div>
    );
};

export default AddonInfo;
