import React from 'react';
import { ip } from '../../../api';
import '../AddonDetailPage.css';

const AddonImages = ({ images }) => {

    return (
        <div id="addon_img_carousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                {images.map((i, index) => (
                    !index ?
                        <button type="button" data-bs-target="#addon_img_carousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        :
                        <button type="button" data-bs-target="#addon_img_carousel" data-bs-slide-to={index} aria-label={`Slide ${index + 1}`}></button>
                ))
                }
            </div>
            <div className="carousel-inner">
                {
                    images.map((i, index) => (
                        <div className={!index ? 'carousel-item active' : 'carousel-item'}>
                            <img src={`https://${ip}${i.image}`} className="d-block w-100" alt={`Addon `} />
                        </div>
                    ))
                }
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#addon_img_carousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#addon_img_carousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};

export default AddonImages;
