import React from 'react';
import '../styles/Loading.css';

const Loading = () => {
    return (
        <div id='loading'>
            <span class="loader-38"></span>
            <h2>Loading</h2>
        </div>
    );
}

export default Loading;