import React, { useState } from 'react';
import '../AddonsPage.css';
import { useTranslation } from 'react-i18next';

const SortMenu = ({ onSortChange, onCategoryChange }) => {
    const { t } = useTranslation();
    const [selectedCategories, setSelectedCategories] = useState([]);

    const handleSortChange = (e) => {
        onSortChange(e.target.value);
    };

    const handleCategoryToggle = (category) => {
        const updatedCategories = selectedCategories.includes(category)
            ? selectedCategories.filter(cat => cat !== category)
            : [...selectedCategories, category];

        setSelectedCategories(updatedCategories);
        onCategoryChange(updatedCategories);
    };

    const categories = ['MTC', 'MCA', 'Tools']; // 실제 카테고리로 대체

    return (
        <div className="sort-menu">
            <label htmlFor="sortCriterion"><span className="material-symbols-outlined">filter_alt</span>{t('AddonsPage_SortMenu_sort')}: </label>
            <select id="sortCriterion" onChange={handleSortChange}>
                <option value="name">{t('AddonsPage_SortMenu_byName')}</option>
                <option value="downloads">{t('AddonsPage_SortMenu_byDownload')}</option>
                <option value="update_date">{t('AddonsPage_SortMenu_byUpdate')}</option>
            </select>
            {/* <div className="categories">
                {categories.map(category => (
                    <label key={category}>
                        <input
                            type="checkbox"
                            value={category}
                            checked={selectedCategories.includes(category)}
                            onChange={() => handleCategoryToggle(category)}
                        />
                        {category}
                    </label>
                ))}
            </div> */}
        </div>
    );
};

export default SortMenu;
