import React from 'react';
import '../AddonsPage.css';
import { useTranslation } from 'react-i18next';

const PageTitle = ({ title }) => {
    const { t } = useTranslation();
    return (
        <header className="page-title">
            <h1><span class="material-symbols-outlined">
                rocket_launch
            </span>{t('AddonsPage_PageTitle')}</h1>
        </header>
    );
};

export default PageTitle;