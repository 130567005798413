import { Outlet } from "react-router-dom"
import Navbar from "./Nav"
import '../styles/main.css'

const Layout = () => {
    return (
        <>
            <Navbar />
            <Outlet />
        </>
    )
}

export default Layout;