/*
    #DarkModeButton
    #다크모드 전환 토글
    2024-10-25
*/

import React, { useState, useEffect } from 'react';

const DarkModeButton = ({ t }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        // 로컬 스토리지에서 테마 가져오기
        const savedTheme = localStorage.getItem('theme');
        let isDarkAtInitial = false;
        if (savedTheme) {
            setIsDarkMode(savedTheme === 'dark');
            isDarkAtInitial = (savedTheme === 'dark');
            document.body.classList.toggle('dark-mode', savedTheme === 'dark');
        } else {
            // 로컬 스토리지가 비어있으면 OS 설정을 따름
            const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
            setIsDarkMode(prefersDarkMode);
            isDarkAtInitial = prefersDarkMode;
            document.body.classList.toggle('dark-mode', prefersDarkMode);
        }

        if (isDarkAtInitial) {
            document.getElementById('isDark').checked = true;
        }
        else {
            document.getElementById('isDark').checked = false;
        }
    }, []);

    const toggleDarkMode = () => {
        const newTheme = isDarkMode ? 'light' : 'dark';
        setIsDarkMode(!isDarkMode);

        document.body.classList.toggle('dark-mode', !isDarkMode);
        localStorage.setItem('theme', newTheme);

    };

    return (
        <label id="dark_toggle" title={t('ui_toggleDarkMode')}>
            <input id='isDark' type="checkbox" onChange={toggleDarkMode} />
            <div className="toggle-slider">
                <span translate='no' className="material-symbols-outlined"> {isDarkMode ? 'dark_mode' : 'light_mode'} </span>
            </div>
        </label>
    )
}

export default DarkModeButton;